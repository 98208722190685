export default function Arrow() {
  return (
    <svg
      className="arrow"
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.57004 0.347778L5.00004 3.91L1.43004 0.347778L0.333374 1.44444L5.00004 6.11111L9.66671 1.44444L8.57004 0.347778Z"
        fill="white"
      />
    </svg>
  );
}
