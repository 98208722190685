import { useEffect, useRef } from 'react';
import '../styles/header.scss';

export default function Header() {
  const menuListRef = useRef(null);
  function closeMenu() {
    document.getElementById('toggle').classList.remove('active');
    document.getElementById('overlay').classList.remove('open');
  }
  useEffect(() => {
    let toggleButton = document.getElementById('toggle');
    let overlay = document.getElementById('overlay');

    toggleButton.addEventListener('click', function () {
      toggleButton.classList.toggle('active');
      overlay.classList.toggle('open');
    });

    return () => {
      toggleButton.removeEventListener('click', function () {});
    };
  }, []);

  useEffect(() => {
    let toggleButton = document.getElementById('toggle');
    let overlay = document.getElementById('overlay');

    function handleClickOutside(event) {
      if (
        menuListRef.current &&
        !menuListRef.current.contains(event.target) &&
        !toggleButton.contains(event.target)
      ) {
        // alert('You clicked outside of me!');
        toggleButton.classList.remove('active');
        overlay.classList.remove('open');
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuListRef]);
  return (
    <header>
      <img className="logo" src="img/logo-png.png" alt="Logo" />
      <div className="button_container" id="toggle">
        <span className="top"></span>
        <span className="middle"></span>
        <span className="bottom"></span>
      </div>
      <div className="overlay" id="overlay">
        <nav className="overlay-menu">
          <ul id="menu-list" ref={menuListRef}>
            <li>
              <a href="#about-us" onClick={closeMenu}>
                À propos
              </a>
            </li>
            <li>
              <a href="#our-services" onClick={closeMenu}>
                Nos Services
              </a>
            </li>
            <li>
              <a href="#our-projects" onClick={closeMenu}>
                Nos Projets
              </a>
            </li>
            <li>
              <a href="#contact" onClick={closeMenu}>
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
