import { useEffect, useRef, useState } from 'react';
import Arrow from '../../Icons/Arrow';
import '../../styles/Home/about-us.scss';
import { aboutUs } from '../../data/aboutUs';

const DropDown = ({ id, openDropdown, setOpenDropdown, data }) => {
  const ref = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.clientHeight);
    }
  }, [ref]);
  return (
    <div
      onClick={() => setOpenDropdown(id)}
      className={`dropdown ${openDropdown === id ? 'dropdown-open' : ''}`}
      style={{
        height: openDropdown === id ? contentHeight + 63 : 40,
      }}
    >
      <div className="header">
        <div className="wrapper">
          <h3 className="number">{data.number}</h3>
          <h3>{data.title}</h3>
        </div>
        <Arrow />
      </div>
      <div className="content" ref={ref}>
        <p>{data.content}</p>
      </div>
    </div>
  );
};

export default function AboutUs() {
  const [openDropdown, setOpenDropdown] = useState(0);
  return (
    <div className="about-us" id="about-us">
      <div className="header">
        <h2>
          À <span> propos</span> de nous
        </h2>
        <div className="line"></div>
        <p>
          Une agence web qui se concentre sur la qualité et l'innovation pour
          votre entreprise.
        </p>
      </div>
      <div className="content">
        <div className="left">
          <video src="img/about-us.mp4" muted autoPlay loop></video>
        </div>
        <div className="right">
          {aboutUs.map((item, index) => (
            <DropDown
              key={index}
              id={index}
              openDropdown={openDropdown}
              setOpenDropdown={setOpenDropdown}
              data={item}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
