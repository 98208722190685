import '../styles/footer.scss';

export default function Footer() {
  return (
    <footer>
      <div className="wrapper">
        <a href="#about">À propos</a>
        <a href="our-services">Nos Services</a>
        <a href="#our-projects">Nos Projets</a>
        <a href="#contact">Contactez-nous</a>
      </div>
      <p>© 2023 Paul-Arthur. Tous droits réservés.</p>
    </footer>
  );
}
