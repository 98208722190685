export const ourServices = [
  {
    icon: 'web',
    title: 'Développement web',
    description:
      'Développement de sites web sur mesure, adaptés à vos besoins.',
    images: ['img/dev-web-1.mp4', 'img/dev-web-2.png'],
  },
  {
    icon: 'draw',
    title: 'Design UI / UX',
    description:
      'Nous créons des interfaces utilisateurs et des expériences utilisateurs uniques.',
    images: ['img/design-1.png', 'img/design-1.mp4'],
  },
  {
    icon: 'query_stats',
    title: 'Référencement',
    description:
      'Optimisation de votre site web pour rendre votre entreprise plus visible sur les moteurs de recherche.',
    images: ['img/seo-1.png', 'img/seo-2.png'],
  },
];
