import '../../styles/Home/hero.scss';

export default function Hero() {
  return (
    <div className="hero">
      <div className="header">
        <div className="top">
          <h1>P2A</h1>
          <h3>
            P2A Développement est une agence de développement web avec plusieurs
            services qui se concentre sur la qualité et l'innovation pour votre
            entreprise.
          </h3>
        </div>
        <div className="bottom">
          <div className="established">
            <p>Depuis</p>
            <p>2020</p>
          </div>
          <h1>Développement</h1>
          <a className="get-in-touch" href="#contact">
            Nous contacter
          </a>
        </div>
      </div>
      <a className="get-in-touch get-in-touch-mobile" href="#contact">
        Nous contacter
      </a>
      <div className="menu-wrapper">
        <div className="list">
          <a href="#about-us" className="active">
            À propos
          </a>
          <a href="#our-services">Nos Services</a>
          <a href="#our-projects">Nos projets</a>
          <a href="#contact">Contact</a>
        </div>
        <div className="image">
          <img src="img/team.jpeg" />
        </div>
      </div>
    </div>
  );
}
