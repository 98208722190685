import { useEffect, useRef, useState } from 'react';
import '../../styles/Home/our-services.scss';
import { ourServices } from '../../data/ourServices';

const DropDown = ({ id, openDropdown, setOpenDropdown, data }) => {
  const ref = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      // if the window size is less than 768px, the height of the dropdown is 40px
      if (window.innerWidth < 868)
        setContentHeight(ref.current.clientHeight + 55);
      else setContentHeight(ref.current.clientHeight);
    }
  }, [ref]);
  return (
    <div
      onClick={() => setOpenDropdown(id)}
      className={`dropdown ${openDropdown === id ? 'dropdown-open' : ''}`}
      style={{
        height:
          openDropdown === id
            ? contentHeight + 70
            : window.innerWidth < 868
            ? 25
            : 40,
      }}
    >
      <div className="header">
        <div className="wrapper">
          <span className="material-symbols-outlined">{data.icon}</span>
          <h3>{data.title}</h3>
        </div>
        <div className="wrapper">
          <p>{data.description}</p>
          <span className="material-symbols-outlined arrow">expand_more</span>
        </div>
      </div>
      <p className="mobile-description">{data.description}</p>
      <div className="content" ref={ref}>
        {data.images.map((image, index) =>
          image.includes('.mp4') ? (
            <div className="image" key={index}>
              <video src={image} muted autoPlay loop></video>
            </div>
          ) : (
            <div className="image" key={index}>
              <img src={image} alt="" />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default function OurServices() {
  const [openDropdown, setOpenDropdown] = useState(0);
  return (
    <div className="our-services" id="our-services">
      <div className="header">
        <h2>
          Nos <span>Services</span>
        </h2>
        <div className="line"></div>
        <p>
          P2A Développement propose une multitude de services pour votre
          entreprise.
        </p>
      </div>
      <div className="dropdowns">
        {ourServices.map((item, index) => (
          <DropDown
            key={index}
            id={index}
            openDropdown={openDropdown}
            setOpenDropdown={setOpenDropdown}
            data={item}
          />
        ))}
      </div>
    </div>
  );
}
