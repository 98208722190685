import '../../styles/Home/contact.scss';

export default function Contact() {
  return (
    <div className="contact" id="contact">
      <div className="top">
        <h3>
          Contactez-<span>nous</span> dès <span>maintenant.</span>
        </h3>
      </div>
      <div className="bottom">
        <div className="wrapper">
          <p className="mail">contact@p2adeveloppement.com</p>
          <div className="line"></div>
          <div className="ws_right"></div>
        </div>
        <a className="get-in-touch" href="mailto:contact@p2adeveloppement.com">
          <p>Contactez-nous</p>
          <span className="material-symbols-outlined arrow">expand_more</span>
        </a>
      </div>
    </div>
  );
}
