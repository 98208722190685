import '../../styles/Home/our-projects.scss';

export default function OurProjects() {
  return (
    <div className="our-projects" id="our-projects">
      <div className="header">
        <h2>
          Nos <span>Projets</span>
        </h2>
        <div className="line"></div>
        <p>
          P2A Développement a travaillé sur plusieurs projets pour des clients
          de différents secteurs.
        </p>
      </div>
      <div className="content">
        <div className="image1">
          <img src="img/project-1.png" />
        </div>
        <div className="image2">
          <img src="img/project-2.png" />
        </div>
        <div className="image3">
          <img src="img/project-5.png" />
        </div>
        <div className="image4">
          <img src="img/project-4.png" />
        </div>
        <div className="image5">
          <img src="img/project-3.png" />
        </div>
      </div>
    </div>
  );
}
