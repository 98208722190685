export const aboutUs = [
  {
    number: 3,
    title: "Années d'expérience",
    content:
      "Possédant plus de 3 ans d'expérience dans le domaine du développement web, nous sommes en mesure de vous offrir un service de qualité.",
  },
  {
    number: '99%',
    title: 'Satisfaction client',
    content:
      "Nous sommes à l'écoute de nos clients et nous nous assurons qu'ils soient satisfaits de notre travail. Nous sommes toujours disponibles pour répondre à vos questions.",
  },
  {
    number: 7,
    title: 'Technologies',
    content:
      'Nous utilisons les dernières technologies pour nos projets. Nous sommes toujours à jour avec les dernières tendances du milieu. ',
  },
];
