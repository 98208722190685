import AboutUs from '../components/Home/AboutUs';
import Contact from '../components/Home/Contact';
import Hero from '../components/Home/Hero';
import OurProjects from '../components/Home/OurProjects';
import OurServices from '../components/Home/OurServices';
import '../styles/Home/home.scss';

export default function Home() {
  return (
    <div className="home">
      <Hero />
      <AboutUs />
      <OurServices />
      <OurProjects />
      <Contact />
    </div>
  );
}
